.gauge_container{
  position: relative;
  margin: auto;
  background-image: url('../../img/gauge-bg.svg');  
  background-repeat: no-repeat;
}

.gauge_container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.needle_container{
  height: 50%;
  width: 100%;
  position: absolute;
}

.needle {
  content: "";
  width: 47%;
  height: 10%;
  position: absolute;
  left: 3%;
  top: 95%;
  background-image: url('../../img/needle-90.svg'); 
  background-repeat: no-repeat; 
  transform-origin: right;
  animation: easeOutElastic 3s ease-in-out 1s forwards;
}

@keyframes easeOutElastic {
	0% {
		transform: rotate(calc(var(--r)*0*1.8deg));
	}

	16% {
		transform: rotate(calc(var(--r)*1.1227*1.8deg));
	}

	28% {
		transform: rotate(calc(var(--r)*0.8688*1.8deg));
	}

	44% {
		transform: rotate(calc(var(--r)*1.0463*1.8deg));
	}

	59% {
		transform: rotate(calc(var(--r)*0.9836*1.8deg));
	}

	73% {
		transform: rotate(calc(var(--r)*1.0058*1.8deg));
	}

	88% {
		transform: rotate(calc(var(--r)*0.998*1.8deg));
	}

	100% {
		transform: rotate(calc(var(--r)*1.8deg));
	}

}